@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Roboto:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&family=Titillium+Web:wght@200;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Jost:wght@100;200;300;400;500;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&family=Titillium+Web:wght@200;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Jost:wght@100;200;300;400;500;700&family=Libre+Baskerville:wght@400;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&family=Titillium+Web:wght@200;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Jost:wght@100;200;300;400;500;700&family=Libre+Baskerville:wght@400;700&family=Poppins:wght@100;200;300;500&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;700&family=Titillium+Web:wght@200;300;600&display=swap');
body{
    background: #fff;
    font-family: 'Poppins', sans-serif;
    /*background: linear-gradient(0deg, rgba(206,214,224,1) 29%, rgba(223,228,234,1) 40%, rgba(241,242,246,1) 84%, rgba(255,255,255,1) 96%);*/
}
nav{
    /*height: 6rem;*/
    /*width: 100vw;*/
    /*display: flex;*/
    /*position: fixed;*/
    z-index: 10;
    background: #2f4491;
}

/*Styling logo*/
.logo{
    padding:1vh 1vw;
    text-align: center;
}
.logo img {
    object-fit: contain;
}
.show-login-btn{
    display: none;
}

.header-bg{
    background: rgb(47,68,145);
    background: linear-gradient(180deg, rgba(47,68,145,1) 27%, rgba(0,137,214,1) 80%);
}

/*Styling Links*/
.nav-links{
    display: flex;
    /*list-style: none;*/
    /*width: 88vw;*/
    padding: 0 0.7vw;
    /*justify-content: space-evenly;*/
    align-items: center;
    color: #fff;
    /*font-weight: bold;*/
    /*text-transform: uppercase;*/
}
.nav-links li a{
    text-decoration: none;
    margin: 0 0.7vw;
}
.nav-links li a:hover {
    color: #f57a27;
}
.nav-links li {
    position: relative;
}
.nav-links li a::after {
    content: "";
    display: block;
    height: 3px;
    width: 0%;
    background-color: #f57a27;
    position: absolute;
    transition: all ease-in-out 250ms;
    margin: 0 0 0 10%;
}
.nav-links li a:hover::after{
    width: 80%;
}

/*Styling Buttons*/
.login-button{
    background-color: transparent;
    color: #fff;
    border: 1.5px solid #f2f5f7;
    border-radius: 2em;
    padding: 0.6rem 0.8rem;
    /*margin-left: 2vw;*/
    font-size: 1rem;
    cursor: pointer;

}
.login-button:hover {
    color: #fff;
    background-color: #f57a27;
    border:1.5px solid #f2f5f7;
    transition: all ease-in-out 350ms;
}
.join-button{
    color: #fff;
    background-color: #f57a27;
    border: 1.5px solid #f57a27;
    border-radius: 2em;
    padding: 0.6rem 0.8rem;
    font-size: 1rem;
    cursor: pointer;
}
.join-button:hover {
    color: #fff;
    background-color: transparent;
    border:1.5px solid #f2f5f7;
    transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div{
    width: 30px;
    height:3px;
    background: #f2f5f7;
    margin: 5px;
    transition: all 0.3s ease;
}
.hamburger{
    display: none;
}

#header-servcies{
    background: rgba(32, 22, 63, 0.3);
}
#header-servcies:hover{
    background: rgba(32, 22, 63, 1);
}





/*Stying for small screens*/
@media screen and (max-width: 800px){
    nav{
        position: fixed;
        z-index: 3;
    }
    .hamburger{
        display:block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
        color: #20163f;
    }
    .toggle{
         color: #fff;
     }
    .nav-links{
        position: fixed;
        right: 0;
        background: #20163f;
        height: 95vh;
        width: 60%;
        border-radius: 0 0 0 20px;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 90% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
        padding-top: 5rem;
        align-items: initial;
    }
    .nav-links.open{
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
    }
    .nav-links li{
        opacity: 0;
        color : #fff;
    }

    .nav-links li a::after {
        content: "";
        display: block;
        height: 1px;
        width: 80%;
        background-color: #fff;
        position: absolute;
        transition: all ease-in-out 250ms;
        margin: inherit;
    }
    .nav-links li:nth-child(1){
        transition: all 0.5s ease 0.2s;
    }
    .nav-links li:nth-child(2){
        transition: all 0.5s ease 0.4s;
    }
    .nav-links li:nth-child(3){
        transition: all 0.5s ease 0.6s;
    }
    .nav-links li:nth-child(4){
        transition: all 0.5s ease 0.7s;
    }
    .nav-links li:nth-child(5){
        transition: all 0.5s ease 0.8s;
    }
    .nav-links li:nth-child(6){
        transition: all 0.5s ease 0.9s;
    }
    .nav-links li:nth-child(7){
        transition: all 0.5s ease 1s;
        margin: 0;
    }
    li.fade{
        margin: 2rem 0 0 1rem;
        opacity: 1;
    }

    /*.fade .login-button{*/
    /*    margin: 2rem 0 0 1rem;*/
    /*}*/
    #phone-slid{
        display: initial !important;
    }

    .show-login-btn{
        display: inline-grid;
        gap: 1rem;
        width: 85%;
        margin: auto !important;
    }
}
/*Animating Hamburger Icon on Click*/
.toggle .line1{
    transform: rotate(-45deg) translate(-5px,6px);
}

.toggle .line2{
    transition: all 0.7s ease;
    width:0;
}
.toggle .line3{
    transform: rotate(45deg) translate(-5px,-6px);
}
header {
    position: relative;
    /*height: 800px;*/
    /*min-height: 500px;*/
    /*width: 100%;*/
    /*background: #161415 url(./images/header-backgroundj.jpg) no-repeat top center;*/
    background-size: cover !important;
    -webkit-background-size: cover !important;
    /*text-align: center;*/
    overflow: hidden;
}

header #header-content{
    /*bottom: 40%;*/
    width: 90%;
    /*height: 50%;*/
}
header #header-content p{
    /*font-family: 'Libre Baskerville', serif;*/
    line-height: 1.5;
}
#slider img{
    object-fit: contain;
}

.book-btn{
    background: #f57a27;
}

.text-pink{
    color: #f57a27;
}
.text-blue{
    color: #30468F;
}
.title{
    /*font-family: 'Poppins',sans-serif;*/
    font-weight: 600;
    font-size: 32px;
    color: #30468F!important;
    line-height: 1.6em;
}

.card {
    font-family: "Quicksand", sans-serif;
    width: 90%;
    max-width: 350px;
    background: rgb(32,22,63);
    background: radial-gradient(circle, rgba(32,22,63,1) 22%, rgba(64,53,96,1) 56%, rgba(245,57,70,1) 66%, rgba(64,53,96,1) 78%);
    padding: 0 2rem 1.5rem 2rem;
    border-radius: 10px;
    transition: box-shadow .3s linear;
}
.card:hover {
    box-shadow: 0 0 35px rgba(0, 0, 0, .3);
}

.card .card-img {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 25px rgba(0, 0, 0, .3);
    transform: translateY(-15%);
    transition: box-shadow .3s linear;
}
.card:hover .card-img {
    box-shadow: none;
}
.card .card-img img {
    width: 100%;
    display: block;
}

.card .card-content {
    text-align: center;
}
.card .card-content .big-title {
    color: #ffffff;
    font-size: 1.3rem;
}
.card .card-content .medium-title {
    color: #ffffff;
    font-size: .85rem;
    margin-top: .3rem;
}

#about #optimisation, #values #integrite{
    color: #3d6fc9;
}
#about #strategie, #values #engagement, .check-icon{
    color: #6fb142;
}
#about #normalisation, #values #creativitee{
    color: #f57a27;
}
#about #intelligence, #values #respetct{
    color: #8238ba;
}


#mission{
    background : rgba(251,229,214,0.5);
}
#solution{
    background : rgba(222,235,247,0.5);
}

#solution button{
    background: #6fb142;
    border: none;
    color: #fff;
    border-radius: 2em;
    padding: 0.6rem 0.8rem;
    cursor: pointer;
}
#solution button:hover{
    background: #f57a27;
}
.check-icon{
    color: #6fb142;
    font-size: 2.5rem !important;
}
.submit-btn{

}
.submit-btn{
    color: #fff;
    background-color: #f57a27;
    border: 1.5px solid #f57a27;
    border-radius: 2em;
    padding: 0.6rem 0.8rem;
}
.submit-btn:hover {
    color: #30468F;
    background-color: transparent;
    border:1.5px solid #30468F;
    transition: all ease-in-out 350ms;
}
footer{
    background-color: #3c3d41;
}
footer ul li{
    margin-top: 0.5rem;
}

footer ul li a:hover{
    color: #f57a27;
}

footer .contact-icon{
    font-size: 2rem !important;
}